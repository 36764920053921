import React from 'react'
import styled from 'styled-components'
import { type UseFormSetValue } from 'react-hook-form'
import { Flex, Label, Radio } from 'pcln-design-system'
import type {
  FlightFormStateType,
  FlightRecordType,
  FLIGHT_TYPE_INFO,
  FLIGHT_TYPE
} from '../types'

const FlightTypeLabel = styled(Label)`
  cursor: pointer;
`

type FlightTypeRadiosProp = {
  flightTypesToDisplay: FLIGHT_TYPE_INFO[]
  setValue: UseFormSetValue<FlightFormStateType>
  flightType: string
  flights: ReadonlyArray<Partial<FlightRecordType>>
}

export default function FlightTypeRadios({
  flightTypesToDisplay,
  setValue,
  flightType,
  flights
}: FlightTypeRadiosProp) {
  return (
    <>
      {flightTypesToDisplay.map(flightInfo => (
        <Flex mr={3} alignItems="center" key={flightInfo.typeLabel}>
          <FlightTypeLabel fontSize={[10, 12]} fontWeight={500} nowrap>
            <Radio
              name="flightType"
              checked={flightType === flightInfo.flightType}
              value={flightInfo.flightType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue('flightType', e.target.value as FLIGHT_TYPE, {
                  shouldDirty: true
                })
                if (Array.isArray(flights) && flights.length > 1) {
                  setValue('flights', [flights[0]], { shouldDirty: true })
                }
              }}
            />
            {flightInfo.typeLabel}
          </FlightTypeLabel>
        </Flex>
      ))}
    </>
  )
}
