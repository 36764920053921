import { format } from 'date-fns/format'
import { isValid } from 'date-fns/isValid'
import { parseISO } from 'date-fns/parseISO'
import qs from 'query-string'
import { urlCreator } from '@pcln/flodash'
import { append, path, remove, update } from 'ramda'
import { type UseFormSetValue } from 'react-hook-form'
import type { LOCATION_SEARCH_TYPE, QueryString } from '@/types'
import { ANALYTICS_CATEGORY_FLIGHTS } from '@/constants/analytics'
import {
  AIR_SEARCH_DATA,
  persistSearchData
} from '@/shared-utils/local-forage-helper'
import { isValidUserActionType } from '@/shared-utils/flight-rebook-helpers'
import { type GA4PageNameType } from '@/ga4types'
import packagesSearch from '@/shared-utils/packages-search'
import fireFlightFormEvent, { fireExpressDealSubmitEvent } from '../ga4'
import {
  MULTI_DESTINATION,
  ONE_WAY,
  ROUND_TRIP,
  FLY,
  HOTEL,
  DRIVE,
  FLIGHT_TYPE_CODE,
  CABIN_CLASS
} from '../constants'
import type {
  FlightRecordType,
  FLIGHT_TYPE,
  FlightFormStateType,
  CABIN_CLASS_CODE_TYPE,
  FLIGHT_TYPE_CODE_TYPE
} from '../types'

const DATE_FORMAT_UI = 'MM/dd/yyyy'
const DATE_FORMAT_FORM_STATE = 'yyyyMMdd'

type FlightSearchRequestType = {
  flightTypeCode: FLIGHT_TYPE_CODE_TYPE | ''
  cabinClassCode: CABIN_CLASS_CODE_TYPE | ''
  adultCount: number
  infantCount?: number
  childrenCount?: number
  flightDetails: FlightRecordType[]
  offerToken?: string
  userActionType?: 'REBOOK' | 'MODIFY'
}

type SlicedFlightInfoType = {
  origAirport: string
  destAirport: string
  origAirportType: string
  destAirportType: string
  departDate: string
}

type OptionParamsType = {
  'cabin-class': string
  'no-date-search': boolean
  'search-type': string
  'num-adults': number
  'num-children': number
  'num-infants': number
  refclickid: string
  'offer-token'?: string
  userActionType?: 'REBOOK' | 'MODIFY'
}

type OptionsType = {
  slices: SlicedFlightInfoType[]
  totalPassengers: number
  params: Partial<OptionParamsType>
  mListings: boolean
  dealsUrl: boolean
  asUrl: boolean
  refclickid?: string
}

export const initialState: FlightFormStateType = {
  flightType: ROUND_TRIP,
  flights: [
    {
      startLocation: {} as LOCATION_SEARCH_TYPE,
      endLocation: {} as LOCATION_SEARCH_TYPE,
      startDate: ''
    }
  ],
  tripType: FLY,
  cabinClass: 'Economy',
  travelers: {
    rooms: 0,
    adults: 1,
    children: 0,
    childrenAges: {},
    lapInfants: 0
  },
  gaCategory: ANALYTICS_CATEGORY_FLIGHTS
}

function parseStringAndFormatAsDate(dateString: string, formatString: string) {
  const parsedDate = parseISO(dateString)
  if (isValid(parsedDate)) {
    return format(parsedDate, formatString)
  }
  return ''
}

function hasMultipleFlights(
  flightType: FLIGHT_TYPE | FLIGHT_TYPE_CODE_TYPE,
  flights: FlightRecordType[] | FlightRecordType
) {
  return (
    Array.isArray(flights) &&
    flights.length > 0 &&
    (flightType === MULTI_DESTINATION ||
      flightType === FLIGHT_TYPE_CODE[MULTI_DESTINATION])
  )
}

function mapFormStateToSearchRequestType(
  formState: FlightFormStateType,
  queryParams?: QueryString
) {
  const { flightType, cabinClass, flights, travelers } = formState
  const flightTypeCode = flightType
    ? FLIGHT_TYPE_CODE[flightType]
    : ('' as const)
  const cabinClassCode = cabinClass ? CABIN_CLASS[cabinClass] : ('' as const)
  const offerToken =
    typeof queryParams?.['offer-token'] === 'string'
      ? queryParams?.['offer-token']
      : ''
  const userActionType = isValidUserActionType(queryParams?.userActionType)
    ? queryParams?.userActionType
    : ''
  return {
    flightTypeCode,
    cabinClassCode,
    flightDetails: flights as FlightRecordType[],
    adultCount: travelers.adults,
    childrenCount: travelers.children,
    infantCount: travelers.lapInfants,
    ...(offerToken && { offerToken }),
    ...(userActionType && { userActionType })
  }
}

/**
 * Each slice corresponds to one leg of a journey i.e. from A -> B on a certain date
 * one-way will have only one slice (A -> B)
 * round-trip will have only two slices (A -> B and B -> A)
 * multi-destination can have more than two slices (A -> B, B -> C, C -> D etc)
 * @param origination
 * @param destination
 * @param travelDate
 */
function buildSlice(
  origination: LOCATION_SEARCH_TYPE,
  destination: LOCATION_SEARCH_TYPE,
  travelDate: string
) {
  const { id: origAirport, coords: originCoords } = origination
  const { id: destAirport, coords: destinationCoords } = destination
  const slice = {
    origAirport,
    destAirport,
    departDate: parseStringAndFormatAsDate(travelDate, DATE_FORMAT_UI)
  }
  if (originCoords && destinationCoords) {
    return {
      ...slice,
      origAirportType: originCoords?.lat && originCoords?.lon ? '0' : '1',
      destAirportType:
        destinationCoords?.lat && destinationCoords?.lon ? '0' : '1'
    }
  }

  return {
    ...slice,
    origAirportType: origination.lat && origination.lon ? '0' : '1',
    destAirportType: destination.lat && destination.lon ? '0' : '1'
  }
}

function buildSlices(formRequestState: FlightSearchRequestType) {
  const { flightTypeCode, flightDetails } = formRequestState
  switch (flightTypeCode) {
    case FLIGHT_TYPE_CODE[ONE_WAY]:
      if (!hasMultipleFlights(flightTypeCode, flightDetails)) {
        const { endLocation, startLocation, startDate } = flightDetails[0]
        return [buildSlice(startLocation, endLocation, startDate)]
      }

      break
    case FLIGHT_TYPE_CODE[ROUND_TRIP]:
      if (!hasMultipleFlights(flightTypeCode, flightDetails)) {
        const {
          endLocation,
          startLocation,
          startDate,
          endDate = ''
        } = flightDetails[0]
        return [
          buildSlice(startLocation, endLocation, startDate),
          buildSlice(endLocation, startLocation, endDate)
        ]
      }

      break
    case FLIGHT_TYPE_CODE[MULTI_DESTINATION]:
      if (hasMultipleFlights(flightTypeCode, flightDetails)) {
        return flightDetails.map(flightInfo => {
          const { endLocation, startLocation, startDate } = flightInfo
          return buildSlice(startLocation, endLocation, startDate)
        })
      }
      break
    default:
      break
  }
  return []
}

function buildRedirect(
  flightSearchRequestData: FlightSearchRequestType,
  sopq = false
) {
  const {
    adultCount,
    childrenCount,
    cabinClassCode,
    infantCount,
    offerToken,
    userActionType
  } = flightSearchRequestData
  const options: OptionsType = {
    slices: buildSlices(flightSearchRequestData),
    totalPassengers: adultCount + (childrenCount ?? 0),
    params: {
      'cabin-class': cabinClassCode,
      'no-date-search': false
    },
    mListings: !sopq,
    dealsUrl: sopq,
    asUrl: false
  }

  options.params['search-type'] = options.slices.reduce(
    (acc, { origAirportType, destAirportType }) =>
      `${acc}${origAirportType}${destAirportType}`,
    ''
  )
  options.params['num-adults'] = adultCount
  if (childrenCount) {
    options.params['num-children'] = childrenCount
  }
  if (infantCount) {
    options.params['num-infants'] = infantCount
  }
  const refClickId = path<string>(
    ['PCLN_BOOTSTRAP_DATA', 'referral', 'clickId'],
    window
  )
  if (refClickId) {
    options.params.refclickid = refClickId
    options.refclickid = refClickId
  }
  if (offerToken) {
    options.params['offer-token'] = offerToken
  }
  if (userActionType) {
    options.params.userActionType = userActionType
  }
  if (sopq) {
    // Takes a slice object and transforms it into a string
    // Ex. SFO-NYC-20201224
    const sliceStrings = options.slices.map(slice => {
      const origAirport = slice.origAirport.toUpperCase()
      const destAirport = slice.destAirport.toUpperCase()
      const formattedDate = format(
        new Date(slice.departDate),
        DATE_FORMAT_FORM_STATE
      )
      return `${origAirport}-${destAirport}-${formattedDate}`
    })
    const concatenatedSlices = sliceStrings.join('/')
    const dealsPath = `m/fly/search/${concatenatedSlices}/deals/`
    const queryParams = qs.stringify(options.params)
    const redirectUrl = `${dealsPath}?${queryParams}`
    return encodeURI(redirectUrl)
  }
  return urlCreator(options)
}

function searchRetail(
  formState: FlightFormStateType,
  queryParams?: QueryString
) {
  const flightSearchRequestData = mapFormStateToSearchRequestType(
    formState,
    queryParams
  )
  const deepLinkUrl = buildRedirect(flightSearchRequestData)
  if (deepLinkUrl) {
    window.open(deepLinkUrl, '_blank')
  }
}

function searchSOPQ(formState: FlightFormStateType) {
  const isSOPQ = true
  const flightSearchRequestData = mapFormStateToSearchRequestType(formState)

  const deepLinkUrl = buildRedirect(flightSearchRequestData, isSOPQ)
  if (deepLinkUrl) {
    window.open(deepLinkUrl, '_blank')
  }
}

function computeChildrenAges(childrenCount: number, infantCount: number) {
  return Array(infantCount)
    .fill('0')
    .concat(Array(childrenCount).fill('11'))
    .reduce<Record<number, string>>((acc, cur, index) => {
      acc[index] = cur
      return acc
    }, {})
}

export const onSubmit = (
  pageName: GA4PageNameType,
  values: FlightFormStateType,
  queryParams?: QueryString
) => {
  const flight = values.flights[0]
  if (
    (values.tripType.includes('STAY') || values.tripType.includes('DRIVE')) &&
    values.flightType === 'round-trip'
  ) {
    const {
      adults: adultCount,
      children: childrenCount,
      lapInfants: infantCount,
      rooms: roomCount
    } = values.travelers

    const totalChildCount = childrenCount + infantCount

    packagesSearch({
      adultCount,
      childrenAges: computeChildrenAges(childrenCount, infantCount),
      childrenCount: totalChildCount,
      endDate: flight.endDate ?? '',
      endLocationObject: flight.endLocation ?? null,
      tripType: values.tripType,
      roomCount,
      startDate: flight.startDate ?? '',
      startLocationObject: flight.startLocation ?? null,
      isPartialStayDateValid: false,
      gaCategory: values.gaCategory ?? ''
    })

    return
  }
  fireFlightFormEvent(initialState, values, pageName)
  void persistSearchData(AIR_SEARCH_DATA, values)

  searchRetail(values, queryParams)
}

export const onSubmitExpressDeal = (
  pageName: GA4PageNameType,
  values: FlightFormStateType
) => {
  fireExpressDealSubmitEvent(pageName)
  searchSOPQ(values)
}

export const setFlights = (
  flights: ReadonlyArray<Partial<FlightRecordType>>,
  data: Partial<FlightRecordType>,
  setValue: UseFormSetValue<FlightFormStateType>,
  index = 0
) => {
  const flightToAdd = { ...flights[index], ...data }
  const result = update(index, flightToAdd, flights)
  setValue('flights', result, { shouldDirty: true })
}

export const addFlight = (
  flights: ReadonlyArray<Partial<FlightRecordType>>,
  setValue: UseFormSetValue<FlightFormStateType>
) => {
  const { endLocation } = flights[flights.length - 1] || {}
  const nextFlight: Partial<FlightRecordType> = {
    startLocation: endLocation,
    endLocation: {} as LOCATION_SEARCH_TYPE
  }
  const result = append(nextFlight, flights)
  setValue('flights', result, { shouldDirty: true })
}

export const removeFlight = (
  flights: ReadonlyArray<Partial<FlightRecordType>>,
  index: number,
  setValue: UseFormSetValue<FlightFormStateType>
) => {
  const result = remove(index, 1, flights)
  setValue('flights', result, { shouldDirty: true })
}

export const getPrevDate = (
  flights: ReadonlyArray<Partial<FlightRecordType>>,
  index: number,
  dateFormat = DATE_FORMAT_FORM_STATE
) => {
  const defaultDate = parseStringAndFormatAsDate(
    new Date().toISOString(),
    dateFormat
  )
  if (index > 0) {
    const { startDate = '' } = flights[index - 1]
    return startDate
      ? parseStringAndFormatAsDate(startDate, dateFormat)
      : defaultDate
  }
  return defaultDate
}

export const fields = [
  'flights',
  'flightType',
  'travelers',
  'tripType',
  'flightType',
  'cabinClass',
  'gaCategory'
] as const

export const isOneWay = (flightType: FLIGHT_TYPE) => flightType === ONE_WAY
export const isRoundTrip = (flightType: FLIGHT_TYPE) =>
  flightType === ROUND_TRIP
export const isMultiDestination = (flightType: FLIGHT_TYPE) =>
  flightType === MULTI_DESTINATION
export const includesStay = (tripType: string) => tripType.includes(HOTEL)
const includesDrive = (tripType: string) => tripType.includes(DRIVE)
export const isPackage = (tripType: string) =>
  includesStay(tripType) || includesDrive(tripType)

export const flightsStartLocationSearchKey = (index: number) =>
  `flights.${index}.startLocation`
export const flightsEndLocationSearchKey = (index: number) =>
  `flights.${index}.endLocation`
export const flightsDateRangeKey = (index: number) =>
  `flight-date-range-${index}`
