import analytics from '@/shared-utils/analytics'
import { type GA4PageNameType } from '@/ga4types'
import { type FlightFormStateType } from './types'
import { GA4TripTypeMapper } from '../BundleAndSave/utils'

export default function fireFlightFormEvent(
  initialState: FlightFormStateType,
  submittedState: FlightFormStateType,
  pageName: GA4PageNameType
) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      cabin_class_change:
        initialState.cabinClass !== submittedState.cabinClass ? 'y' : 'n',
      date_change:
        initialState.flights[0].startDate !==
        submittedState.flights[0].startDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.flights[0].startLocation !==
        submittedState.flights[0].startLocation
          ? 'y'
          : 'n',
      trip_end_city_change:
        initialState.flights[0].endLocation !==
        submittedState.flights[0].endLocation
          ? 'y'
          : 'n',
      quantity_change:
        initialState.travelers.adults !== submittedState.travelers.adults ||
        initialState.travelers.children !== submittedState.travelers.children ||
        initialState.travelers.lapInfants !==
          submittedState.travelers.lapInfants ||
        initialState.travelers.rooms !== submittedState.travelers.rooms
          ? 'y'
          : 'n',
      page_name: pageName,
      product_name: 'air'
    }
  })
}

export function fireBundleAndSaveEvent(tripType: string) {
  const mappedTripType = GA4TripTypeMapper(tripType)
  analytics.fireGA4Event({
    event: 'select_promotion',
    attributes: {
      type: 'add_on',
      product_name: 'air',
      bundle_flag: 'y',
      bundle_type: mappedTripType
    }
  })
}

export function fireExpressDealSubmitEvent(pageName: GA4PageNameType) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      page_name: pageName,
      product_name: 'air'
    }
  })
}
